// import { useLoaderData, useNavigation } from '@ubo/losse-sjedel'
import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { json, useLoaderData, wordpressLoader } from '@ubo/losse-sjedel'
import type { LoaderData, LoaderFunction } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
// import { useEffect } from 'react'
// import progress from 'nprogress'
// import FlexContentHandler from '~/components/flex/FlexContentHandler'
// import Layout from '~/components/layout/Layout'

// export { loader }

export const loader: LoaderFunction = async (data) => {
  // console.log('data', data)

  const url = new URL(data.request.url)
  const host = url.host

  let _data = { ...data }
  // @ts-ignore
  _data.request.url = `${host}/`

  console.log('_data', _data)

  const page = await wordpressLoader(_data)

  return json(page.body, page.headers)
}

export default function $page() {
  const {
    page: { flexContent }
  } = useLoaderData<LoaderData>()

  const flexcontentFragments = flexContent?.flex?.filter((item) => item?.fieldGroupName === 'page_Flexcontent_Flex_Fragment')
  const newsletterFragment = flexcontentFragments?.find(
    (item: any) => item?.element?.flexContent?.flex?.[0]?.styletype === 'newsletter'
  ) as any

  // const transition = useNavigation()
  // useEffect(() => {
  //   if (transition.state === 'loading' || transition.state === 'submitting') {
  //     progress.start()
  //   } else {
  //     progress.done()
  //   }
  // }, [transition.state])
  // return (
  //   <Layout>
  //     <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex as any} />
  //   </Layout>
  // )

  return (
    <div className="bg-site-accent-light min-h-screen px-4 py-16 lg:px-6 lg:py-24 lg:grid lg:place-items-center">
      <div className="max-w-max mx-auto">
        <main>
          <div className="lg:flex">
            <p className="text-4xl font-site-base font-extrabold text-site-base lg:text-5xl">Hofwyck Rengers</p>
            <div className="lg:ml-6">
              <div className="lg:border-l lg:border-site-accent lg:pl-6">
                <h1 className="text-4xl font-extrabold text-site-text tracking-tight lg:text-5xl">Onder constructie</h1>
                <p className="mt-1 text-base text-site-text/75">De website voor fase 2 gaat binnenkort online.</p>
              </div>
            </div>
          </div>

          {newsletterFragment?.element?.flexContent?.flex?.[0]?.form && (
            <section id="inschrijven" data-component="FormNewsletter" className="bg-site-accent p-5 mt-32 relative">
              <h2 className="text-4xl pb-2 text-center font-site-accent font-bold">Op de hoogte blijven van ontwikkelingen?</h2>
              <h3 className="text-4xl text-center font-site-accent font-light italic">Meld je aan voor de nieuwsbrief!</h3>
              <div className="flex justify-center pt-12">
                <Form
                  data={newsletterFragment?.element?.flexContent?.flex?.[0]?.form}
                  renderLoading={() => <Loading />}
                  renderStatus={({ content }) => <Content className="content p-10">{content}</Content>}
                  generate={false}
                  scrollToConfirm={false}
                  className="bg-site-accent"
                >
                  <div className="flex items-center md:gap-4 flex-col md:flex-row">
                    <FormDuufGroup />
                    <FormDuufSubmit />
                  </div>
                </Form>
              </div>
            </section>
          )}
        </main>
      </div>
    </div>
  )
}
